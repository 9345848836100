import React from "react"
import {
  Container,
  Layout,
  SEO,
  Grid,
  TextRow,
  ImageRow,
  ExternalLink,
  Tag,
} from "../../components"

import example from "../../images/projects/astrolink-pwa.png"

const AstrolinkPWA = () => (
  <Layout hideProfile>
    <SEO title="Project: Progressive Web App - Astrolink" />
    <Container>
      <h1>Progressive Web App - Astrolink</h1>
      <Grid>
        <TextRow>
          <div>
            <Tag>React</Tag>
            <Tag>Redux</Tag>
            <Tag>Route-based code splitting</Tag>
            <Tag>Dynamic import</Tag>
            <Tag>Pre-caching</Tag>
            <Tag>Runtime caching</Tag>
            <Tag>Cache Strategies</Tag>
            <Tag>Jest</Tag>
            <Tag>Enzyme</Tag>
            <Tag>Webpack</Tag>
            <Tag>Babel</Tag>
          </div>

          <p>
            Astrolink is a social network about astrology which allows users to
            create their own birth chart free and see their horoscope daily.
            There are more than <strong>2 million</strong> users registered.
          </p>
          <p>
            I worked as an <strong>author and engineering leader</strong> for
            the purpose of to build new features and optimize performance to
            serve a large number of concurrent users.
          </p>
          <p>
            The <strong>PWA</strong> project was one of the big projects that I
            worked at the Astrolink team. I was the initiator of this project to
            solve a problem that was to provider a mobile experience for our
            users.
          </p>
        </TextRow>

        <ImageRow>
          <img alt="Tangram design system example" src={example} />
        </ImageRow>

        <TextRow>
          <p>
            We used several techniques to optimize performance and allow our
            product to <strong>work offline</strong> and work well on mobile
            devices. <br />
            Techniques like code-splitting, dynamic imports and cache strategies
            were used to deal with performance optimization.
          </p>
          <p>
            In 2019, I wrote a article describing more details about this
            implementation, where I do deeper into issues related to techniques
            used in Progressive Web App.
          </p>
          <ExternalLink
            href="https://medium.com/@jonasantonelli/progressive-web-app-case-study-astrolink-377300c717fa"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read more about it
          </ExternalLink>
        </TextRow>
      </Grid>
    </Container>
  </Layout>
)

export default AstrolinkPWA
